:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #1976d2;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
}

.text {
    font-size: 22px;
    font-weight: normal;
    line-height: 1.5;
}

@media print {
    body * {
        visibility: hidden;
    }
    #printableArea,
    #printableArea * {
        visibility: visible;
    }
    /* #printableArea {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    } */
}
